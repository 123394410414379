import React from 'react';
import { faHourglassEnd, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import getTipoTorneo from '../utils/getTipoTorneo';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import freeTicketIcon from '../../img/free-200.png';
import gemIcon from '../../img/gem.png';
import mobileIcon from '../../img/icon_mobile.png';
import raceRoomGif from '../../img/race_room.gif';

const SalasDesktop = ({ gameComponent, juego, textosShow, defaultData, gameData, onPlayClick,
  referralContests, onPlayGeneralJackpotClick, generaljackpots, openPopupOnlyPlayOnMobile }) => {

  const getCustomTitleClass = (sala, index) => {
    if (sala && sala.custom_title && index == 0) {
      return 'custom_title';
    }

    return '';
  }

  const getCustomTitleStyle = (sala, index) => {
    if (sala && sala.custom_title && sala.custom_title_color && index == 0) {
      return sala.custom_title_color;
    }

    return '';
  }

  const getButtonTitle = (sala) => {
    if (sala.modo == 'Ladder' && !sala.ladderRegistered)  {
        return (textosShow.inscribirse || "Inscribirse");
    } else if (sala.modo == 'Bonanza' && !sala.bonanzaRegistered)  {
        return (textosShow.inscribirse || "Inscribirse");
    } else if (sala.showReentry && sala.costoReentry && parseFloat(sala.costoReentry) > 0)  {
      return (textosShow.reentrarpor || "Reentrar por") + " " + defaultData.moneda.signo + sala.costoReentry;
    } else if (sala.showReentry)  {
        return (textosShow.reentrar || "Reentrar");
    } else {
        return (textosShow.entrar || "Entrar");
    }
  }

  const getEntryCosto = (sala) => {
    if (sala.modo == 'Ladder' && sala.ladderRegistereds) {
      return sala.costoChallenge;
    }
    if (sala.modo == 'Bonanza' && sala.bonanzaRegistereds) {
      return sala.costoChallenge;
    }

    return sala.costo;
  }

  const isValid = (sala) => {
    return !(sala.modo == 'Ladder' && sala.ladderStatus == 2)
          && !(sala.modo == 'Bonanza' && sala.bonanzaStatus == 2)
          && !(sala.modo == "Jackpots" && sala.tiempo.dias == 0 && sala.tiempo.horas == 0 && sala.tiempo.minutos == 0 && sala.tiempo.segundos == 0)
  }

  const getRewardRemainingTime = (sala) => {
    
    if (!sala) return;

    var curDate = new Date();
    curDate = new Date(curDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    var hour = curDate.getHours();
    var minute = curDate.getMinutes();
    var second = curDate.getSeconds();

    var rHours = 0;
    var rMinutes = 0;
    var rSeconds = 0;

    var rewardType = '';
    var rewardMinutes = 0;

    if (sala.modo == 'Ladder') {
      rewardType = sala.ladderRewardType;
      rewardMinutes = sala.ladderRewardMinutes;
    } else if (sala.modo == 'Bonanza') {
      rewardType = sala.bonanzaRewardType;
      rewardMinutes = sala.bonanzaRewardMinutes;
    }

    switch(rewardType) {
      case 'Daily':
        rSeconds = 0 - second;
        rMinutes = 0 - minute;
        rHours = 24 - hour;
        if (rSeconds < 0) {
          rSeconds += 60;
          rMinutes -= 1;
        }
        if (rMinutes < 0) {
          rMinutes += 60;
          rHours -= 1;
        }
        break;

      case 'Hourly':
        rSeconds = 0 - second;
        rMinutes = 0 - minute;
        if (rSeconds < 0) {
          rSeconds += 60;
          rMinutes -= 1;
        }
        if (rMinutes < 0) {
          rMinutes += 60;
          rHours = 0;
        }
        break;
      case 'Minutes':
        var sMinute = rewardMinutes ? rewardMinutes : 0;
        minute = Math.floor(minute % sMinute);

        rSeconds = 0 - second;
        rMinutes = sMinute - minute;
        if (rSeconds < 0) {
          rSeconds += 60;
          rMinutes -= 1;
        }
        break;
    }
    
    return (rHours + ':' + rMinutes + ':' + rSeconds);
  }

  const getParentClassName = (generaljackpot) => {
    let className = [];
    if (generaljackpot.type) {
      //if type finish with per_game
      if (generaljackpot.type.endsWith('per_game')) {
        className.push('mega-featured-orange');
      } else {
        className.push('mega-featured');
      }
    } else {
      className.push('featured');
    }

    if(generaljackpot.tiempo.highlight){
      className.push('highlight');
    }

    return className.join(' ');
  }

  return (
    <table>
      <thead>
        <tr className='subtitle'>
          <td>
            <span>
              {textosShow.costedeentradamin || "Coste de entrada"}
            </span>
          </td>
          <td>
            <span>
              {textosShow.premioalganador || "Premio al ganador"}
            </span>
          </td>
          <td>
            <span>
              {textosShow.tipodetorneo || "Tipo de torneo"}
            </span>
          </td>
          <td colSpan="2"></td>
        </tr>
      </thead>
      <tbody>
        {
          [...referralContests, ...generaljackpots].map((generaljackpot) => (
            <tr
              className={`${getParentClassName(generaljackpot)} `}
              key={`${generaljackpot.id}-${generaljackpot.type}`}>
              <td className='entry_pay'>
                <span>
                  {defaultData.moneda.signo} {generaljackpot.costo || '0'}
                </span>
              </td>
              <td className='prize_td'>
                <span className={`sign_length_${defaultData.moneda.signo.length}`}>
                  <p>
                    {defaultData.moneda.signo}
                  </p>
                  {generaljackpot.dinero}
                </span>
                <div className='tour_add ic_close'>
                  +{generaljackpot.gemas}
                </div>
              </td>
              <td className='tour_info'>
                <span>
                  <b>
                    <span>
                      {!generaljackpot.type ?
                        (textosShow.competenciareferido || "Competencia referidos")
                        :
                        (textosShow['generaljackpot_' + generaljackpot.type] || generaljackpot.type)
                      }
                      <span
                        data-tip
                        data-for={generaljackpot.type ? `generaljackpot_${generaljackpot.id}` : `referral-contenst-info-${generaljackpot.id}`}
                        className="iconInfo pl-1">
                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                      </span>
                      <ReactTooltip id={generaljackpot.type ? `generaljackpot_${generaljackpot.id}` : `referral-contenst-info-${generaljackpot.id}`}>
                        <p>
                          {!generaljackpot.type ?
                            (textosShow.descripciondecompetenciadereferidos || "Descripción de competencia de referidos")
                            :
                            (textosShow['descripciongeneraljackpot_' + generaljackpot.type] || generaljackpot.type)
                          }
                        </p>
                      </ReactTooltip>
                    </span>
                  </b>
                  { generaljackpot.custom_title ? (
                      <b className='custom_title' style={{color: generaljackpot.custom_title_color}}>
                        {generaljackpot.custom_title}
                      </b>
                    ) : (<br/>)
                  }
                  {
                    generaljackpot.juego && (
                      juego ? (
                        <>
                          {/* Showed in screen that have only one game */}
                          <span className="d-flex justify-content-end" data-tip data-for={`generaljackpot_race_logo_${generaljackpot.id}`}>
                            <img className="sala-desktop-race-logo" src={raceRoomGif} />
                          </span>
                          <ReactTooltip id={generaljackpot.type ? `generaljackpot_race_logo_${generaljackpot.id}` : `referral-contenst-info-${generaljackpot.id}`}>
                            <p>
                              {(textosShow.generaljackpot_megaevent || "Mega Event")}
                            </p>
                          </ReactTooltip>
                        </>
                      ) : (
                        <>
                          {/* Showed in screen that have many games */}
                          <span className="d-flex justify-content-between">
                            <b>
                              {generaljackpot.juego.slug}
                            </b>
                            <Link to={`/game/${generaljackpot.juego.slug}`}>
                              <img className="sala-desktop-game-logo" src={generaljackpot.juego.logo} />
                            </Link>
                          </span>
                          <span className="d-flex">
                            <Link to={`/game/${generaljackpot.juego.slug}`}>
                              {textosShow.viewrooms || "View Rooms"}
                            </Link>
                          </span>
                        </>
                      )
                    )
                  }
                  <span className='more_info'>
                    {textosShow.masinfo || "Más info"}
                  </span>
                </span>
              </td>
              <td className="tour_info reamaining">
                {
                  <span>
                    {textosShow.tiemporestante || "Tiempo restante"}
                    <br />
                    {
                      generaljackpot.tiempo.highlight ? (
                        <span className="iconTimeRemaining pr-1">
                          <FontAwesomeIcon
                            icon={faHourglassEnd}
                            size="lg" />
                        </span>
                      ) : (
                        <span className="iconTimeRemaining pr-1">
                          <FontAwesomeIcon
                            icon={faHourglassHalf}
                            size="lg" />
                        </span>
                      )
                    }
                    <span className="remaining_time">
                      {generaljackpot.tiempo.dias}d {generaljackpot.tiempo.horas}:{generaljackpot.tiempo.minutos}:{generaljackpot.tiempo.segundos}
                    </span>
                  </span>
                }
              </td>
              <td className='keep_training'>
                <div
                  className='mt_block step_4'
                  id={'deposit_now_' + generaljackpot.id}>
                  <div className='step'></div>
                  {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                  <div className='notif_buttons'>
                    <button className="button green_button">
                      <Link to="/deposit">
                        {textosShow.depositarahora || "Depositar ahora"}
                      </Link>
                    </button>
                  </div>
                </div>

                {/*
                <div
                  className='mt_block step_5'
                  id={'subscribe_now_' + generaljackpot.id}>
                  <div className='step'></div>
                  {textosShow.sololasusuariospremiumpuedenparticiparentorneosfreeroll || "Only premium users can enter freeroll tournaments."}
                  <div className='notif_buttons'>
                    <button className="button green_button">
                      <Link to="/subscription">
                        {textosShow.suscribirarahora || "Suscribir"}
                      </Link>
                    </button>
                  </div>
                </div>

                <div
                  className='mt_block step_5'
                  id={'subscribe_now_more_' + generaljackpot.id}>
                  <div className='step'></div>
                  {textosShow.hasllegadoallimite || "You have reached the limit of 5 free games per day, upgrade your account to continue playing."}
                  <div className='notif_buttons'>
                    <button className="button green_button">
                      <Link to="/subscription">
                        {textosShow.suscribirarahora || "Suscribir"}
                      </Link>
                    </button>
                  </div>
                </div>  
              */}
                              

                <button
                  className="button deposit_button enter_button"
                  onClick={onPlayGeneralJackpotClick.bind(gameComponent, generaljackpot)}>
                  {
                    !generaljackpot.registered ? (
                      textosShow.inscribirse || "Inscribirse"
                    ) : (
                      textosShow.infodeljuego || "Info del Juego"
                    )
                  }
                </button>
              </td>
            </tr>
          ))
        }
        {
          juego ? juego.salas.map((sala) => isValid(sala) && (
            <tr
              className={getTipoTorneo(textosShow, juego, sala).classes.join(" ") + `${sala.onlyOnMobile === 1 ? ' mobile-only' : ''}`}
              key={sala.id}>
              <td className='entry_pay' style={{zIndex:"1000"}}>
                <div className='d_flex'>
                  <span className={(juego.freeJuegoSalaId === sala.id /*|| sala.showReentry*/) ? 'strike' : ''}>
                    {defaultData.moneda.signo} {getEntryCosto(sala)}
                  </span>
                  { (juego.freeJuegoSalaId === sala.id && !juego.throughPromoCode) ?
                    <img className='free_icon'
                      src={freeTicketIcon}
                      width='50px'/>
                      : 
                      null
                  }
                  {/* (sala.showReentry) &&
                    <span>
                      {defaultData.moneda.signo} {sala.costoReentry}
                    </span>
                */}
                  { (juego.freeJuegoSalaId === sala.id && juego.throughPromoCode) ?
                  <div>
                    <img data-tip data-for="promoCodeTooltip" className='free_icon' src={freeTicketIcon}
                      width='50px'/>
                      <ReactTooltip id="promoCodeTooltip">
                          <p>
                            {textosShow.thisticketwasredeemedinthepromocodeticket || 'This ticket was redeemed in the promo code ticket'}
                          </p>
                      </ReactTooltip>
                  </div>
                    : 
                  null
                  }
                </div>
              </td>
              <td className='prize_td'>
                { (sala.modo == 'Ladder') ?
                   <>
                     <span className={`sign_length_${defaultData.moneda.signo.length}`}>
                        <p><img className='iconBlink' src={gemIcon}/></p>{sala.totalLDRs + sala.totalLFRs}
                    </span>
                   </>
                   : ((sala.modo == 'Bonanza') ?
                    <>
                      <span className={`sign_length_${defaultData.moneda.signo.length}`}>
                        <p className='iconBlink'>{defaultData.moneda.signo}</p>{Number(sala.totalBDRs + sala.totalBFRs).toFixed(2)}
                      </span>
                    </> :
                    <>
                      <span className={`sign_length_${defaultData.moneda.signo.length}`}>
                        <p>{defaultData.moneda.signo}</p>{sala.premioTotal}
                      </span>
                      <div className='tour_add ic_close'>
                        +{sala.gemas}
                      </div>
                    </>)
                }
                
              </td>
              <td className='tour_info'>
                <span>
                  <b>
                    {getTipoTorneo(textosShow, juego, sala).data.map((data, index) => (
                      <span key={data.id} className={getCustomTitleClass(sala, index)} style={{color: getCustomTitleStyle(sala, index)}}>
                        {index > 0 ? " / " : ""}
                        {data.title}
                        {data.icon ?
                          (
                            <span data-tip data-for={data.id} className={`${data.iconContainerClass} pl-1`}>
                              {data.icon}
                            </span>
                          ) :
                          (null)
                        }
                        {data.description ?
                          (
                            <ReactTooltip id={data.id}>
                              <p>
                                {data.description}
                              </p>
                            </ReactTooltip>
                          ) :
                          (null)
                        }
                      </span>
                    ))}
                  </b>
                  {(sala.cantJugador === -1) ? '∞' : sala.cantJugador}-{textosShow.jugadores || "Jugadores"}
                  <br />
                  <span className='more_info'>
                    {textosShow.masinfo || "Más info"}
                  </span>
                </span>
              </td>
              <td className="tour_info reamaining">
                {
                  (sala.onlyOnMobile === 1) ? (
                    <div className="mobile-icon-box">
                      <div className="mobile-icon">
                        <img src={mobileIcon} />
                      </div>
                      <div className="mobile-label">
                        <span>PLAY</span>
                        <br />
                        <span>ON MOBILE</span>
                      </div>
                    </div>
                  ) : (
                    null
                  )
                }

                {
                  (sala.modo === "Jackpots" ) && sala.onlyOnMobile === 0 &&
                    <span>
                      {textosShow.tiemporestante || "Tiempo restante"}
                      <br />
                      {sala.tiempo.highlight ? (
                        <span className="iconTimeRemaining pr-1"><FontAwesomeIcon icon={faHourglassEnd} size="lg" /></span>
                      ) : (
                        <span className="iconTimeRemaining pr-1"><FontAwesomeIcon icon={faHourglassHalf} size="lg" /></span>
                      )}
                      <span className="remaining_time">
                        {sala.tiempo.dias}d {sala.tiempo.horas}:{sala.tiempo.minutos}:{sala.tiempo.segundos}
                      </span>
                    </span>
                  
                }
                { (sala.modo === "Ladder" || sala.modo === 'Bonanza') && sala.onlyOnMobile === 0 &&
                  <span>
                    {textosShow.tiemporestante || "Tiempo restante"}
                    <br />
                    {sala.tiempo.highlight ? (
                      <span className="iconTimeRemaining pr-1"><FontAwesomeIcon icon={faHourglassEnd} size="lg" /></span>
                    ) : (
                      <span className="iconTimeRemaining pr-1"><FontAwesomeIcon icon={faHourglassHalf} size="lg" /></span>
                    )}
                    <span className="remaining_time">
                      {getRewardRemainingTime(sala)}
                    </span>
                  </span>
                }
              </td>

              {
                (sala.onlyOnMobile === 1) ? (
                  <td className='keep_training' onClick={() => openPopupOnlyPlayOnMobile()}>
                    <div
                      className='mt_block step_4'
                      id={'deposit_now_' + sala.id}>
                      <div className='step'></div>
                      {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                      <div className='notif_buttons'>
                        <button className="button green_button">
                          <Link to="/deposit">
                            {textosShow.depositarahora || "Depositar ahora"}
                          </Link>
                        </button>
                      </div>
                    </div>

                    <div
                      className='mt_block step_5'
                      id={'deposit_free_' + sala.id}>
                      <div className='step'></div>
                      {textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis"}
                      <div className='notif_buttons'>
                        <button className="button green_button">
                          <Link to="/deposit">
                            {textosShow.depositarahora || "Depositar ahora"}
                          </Link>
                        </button>
                      </div>
                    </div>

                  {/*
                    <div
                      className='mt_block step_5'
                      id={'subscribe_now_' + sala.id}>
                      <div className='step'></div>
                      {textosShow.sololasusuariospremiumpuedenparticiparentorneosfreeroll || "Only premium users can enter freeroll tournaments."}
                      <div className='notif_buttons'>
                        <button className="button green_button">
                          <Link to="/subscription">
                            {textosShow.suscribirarahora || "Suscribir"}
                          </Link>
                        </button>
                      </div>
                    </div>

                    <div
                      className='mt_block step_5'
                      id={'subscribe_now_more_' + sala.id}>
                      <div className='step'></div>
                      {textosShow.hasllegadoallimite || "You have reached the limit of 5 free games per day, upgrade your account to continue playing."}
                      <div className='notif_buttons'>
                        <button className="button green_button">
                          <Link to="/subscription">
                            {textosShow.suscribirarahora || "Suscribir"}
                          </Link>
                        </button>
                      </div>
                    </div> 
                    <div className="lock-chain">
                    </div>
                */}
                    <button className="button deposit_button enter_button">
                      {getButtonTitle(sala)}
                    </button>
                  </td>
                ) : (
                  <td className='keep_training'>
                    <div
                      className='mt_block step_4'
                      id={'deposit_now_' + sala.id}>
                      <div className='step'></div>
                      {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                      <div className='notif_buttons'>
                        <button className="button green_button">
                          <Link to="/deposit">
                            {textosShow.depositarahora || "Depositar ahora"}
                          </Link>
                        </button>
                      </div>
                    </div>

                    <div
                      className='mt_block step_5'
                      id={'deposit_free_' + sala.id}>
                      <div className='step'></div>
                      {textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis"}
                      <div className='notif_buttons'>
                        <button className="button green_button">
                          <Link to="/deposit">
                            {textosShow.depositarahora || "Depositar ahora"}
                          </Link>
                        </button>
                      </div>
                    </div>

                  {/*
                    <div
                      className='mt_block step_5'
                      id={'subscribe_now_' + sala.id}>
                      <div className='step'></div>
                      {textosShow.sololasusuariospremiumpuedenparticiparentorneosfreeroll || "Only premium users can enter freeroll tournaments."}
                      <div className='notif_buttons'>
                        <button className="button green_button">
                          <Link to="/subscription">
                            {textosShow.suscribirarahora || "Suscribir"}
                          </Link>
                        </button>
                      </div>
                    </div>

                    <div
                      className='mt_block step_5'
                      id={'subscribe_now_more_' + sala.id}>
                      <div className='step'></div>
                      {textosShow.hasllegadoallimite || "You have reached the limit of 5 free games per day, upgrade your account to continue playing."}
                      <div className='notif_buttons'>
                        <button className="button green_button">
                          <Link to="/subscription">
                            {textosShow.suscribirarahora || "Suscribir"}
                          </Link>
                        </button>
                      </div>
                    </div>         
                */}       

                    <button
                      className="button deposit_button enter_button"
                      onClick={onPlayClick.bind(gameComponent, sala)}>
                        {getButtonTitle(sala)}
                    </button>
                  </td>
                )
              }
              <div className='bg-padlock'></div>
            </tr>
          )) : (null)
        }
      </tbody>
    </table>
  )
}

export default SalasDesktop;
