import React, { useState } from 'react';
import { faHourglassEnd, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardText, Col, Collapse, Container, Row } from 'reactstrap';
import getTipoTorneo from '../utils/getTipoTorneo';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import freeTicketIcon from '../../img/free-200.png';
import gemIcon from '../../img/gem.png';
import raceRoomGif from '../../img/race_room.gif';
import ReactTooltip from 'react-tooltip';

const SalasMobile = ({ gameComponent, juego, textosShow, defaultData, gameData, onPlayClick,
  showDepositNow, showDepositFree, referralContests, onPlayGeneralJackpotClick, generaljackpots }) => {
  const [seeDetail, setSeeDetail] = useState(false);


  const getCustomTitleClass = (sala, index) => {
    if (sala && sala.custom_title && index == 0) {
      return 'custom_title';
    }

    return '';
  }

  const getCustomTitleStyle = (sala, index) => {
    if (sala && sala.custom_title && sala.custom_title_color && index == 0) {
      return sala.custom_title_color;
    }

    return '';
  }

  const getButtonTitle = (sala) => {
    if (sala.modo == 'Ladder' && !sala.ladderRegistered) {
        return (textosShow.inscribirse || "Inscribirse");
    } else if (sala.modo == 'Bonanza' && !sala.bonanzaRegistered) {
      return (textosShow.inscribirse || "Inscribirse");
    } else if (sala.showReentry && sala.costoReentry && parseFloat(sala.costoReentry) > 0)  {
      return (textosShow.reentrarpor || "Reentrar por") + " " + defaultData.moneda.signo + sala.costoReentry;
    } else if (sala.showReentry)  {
        return (textosShow.reentrar || "Reentrar");
    } else {
      return (textosShow.entrar || "Entrar");
    }
  }

  const getEntryCosto = (sala) => {
    if (sala.modo == 'Ladder' && sala.ladderRegistereds) {
      return sala.costoChallenge;
    }
    if (sala.modo == 'Bonanza' && sala.bonanzaRegistereds) {
      return sala.costoChallenge;
    }

    return sala.costo;
  }

  const isValid = (sala) => {
    return !(sala.modo == 'Ladder' && sala.ladderStatus == 2)
          && !(sala.modo == 'Bonanza' && sala.bonanzaStatus == 2)
          && !(sala.modo == "Jackpots" && sala.tiempo.dias == 0 && sala.tiempo.horas == 0 && sala.tiempo.minutos == 0 && sala.tiempo.segundos == 0)
  }

  const getRewardRemainingTime = (sala) => {
    
    if (!sala) return;

    var curDate = new Date();
    curDate = new Date(curDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    var hour = curDate.getHours();
    var minute = curDate.getMinutes();
    var second = curDate.getSeconds();

    var rHours = 0;
    var rMinutes = 0;
    var rSeconds = 0;

    var rewardType = '';
    var rewardMinutes = 0;

    if (sala.modo == 'Ladder') {
      rewardType = sala.ladderRewardType;
      rewardMinutes = sala.ladderRewardMinutes;
    } else if (sala.modo == 'Bonanza') {
      rewardType = sala.bonanzaRewardType;
      rewardMinutes = sala.bonanzaRewardMinutes;
    }

    switch(rewardType) {
      case 'Daily':
        rSeconds = 0 - second;
        rMinutes = 0 - minute;
        rHours = 24 - hour;
        if (rSeconds < 0) {
          rSeconds += 60;
          rMinutes -= 1;
        }
        if (rMinutes < 0) {
          rMinutes += 60;
          rHours -= 1;
        }
        break;

      case 'Hourly':
        rSeconds = 0 - second;
        rMinutes = 0 - minute;
        if (rSeconds < 0) {
          rSeconds += 60;
          rMinutes -= 1;
        }
        if (rMinutes < 0) {
          rMinutes += 60;
          rHours = 0;
        }
        break;
      case 'Minutes':
        var sMinute = rewardMinutes ? rewardMinutes : 0;
        minute = Math.floor(minute % sMinute);

        rSeconds = 0 - second;
        rMinutes = sMinute - minute;
        if (rSeconds < 0) {
          rSeconds += 60;
          rMinutes -= 1;
        }
        break;
    }
    
    return (rHours + ':' + rMinutes + ':' + rSeconds);
  }

  const getParentClassName = (generaljackpot) => {
    let className = [];
    if (generaljackpot.type) {
      //if type finish with per_game
      if (generaljackpot.type.endsWith('per_game')) {
        className.push('mega-featured-orange');
      } else {
        className.push('mega-featured');
      }
    } else {
      className.push('featured');
    }

    if(generaljackpot.tiempo.highlight){
      className.push('highlight');
    }

    return className.join(' ');
  }

  return (
    <Container fluid>
      <Row>
        {
          [...referralContests, ...generaljackpots].map((generaljackpot) => (
            <Col
              xs="12"
              sm="6"
              className={`rounded-lg py-3 tour_info ${getParentClassName(generaljackpot)}`}
              key={`${generaljackpot.id}-${generaljackpot.type}`}>
              <Card className="rounded-lg shadow border-secondary h-100">
                <CardHeader className='p-0'>
                  <Row className="w-100 m-0">
                    <Col
                      xs="5"
                      className='entry_pay my-auto align-content-center'>
                      <div className="title">
                        {textosShow.costedeentradamin || "Coste de entrada"}
                      </div>
                      <div>
                        {defaultData.moneda.signo}{generaljackpot.costo || '0'}
                      </div>
                    </Col>
                    <Col
                      xs="7"
                      className='prize_card_header p-3'>
                      <div className="title">
                        {textosShow.premioalganador || "Premio al ganador"}
                      </div>
                      <span className="sign_length_1">
                        <p>
                          {defaultData.moneda.signo}
                        </p>
                        {generaljackpot.dinero}
                      </span>
                      <div className='tour_add ic_close'>
                        +{generaljackpot.gemas}
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="salas-mobile-card-body">
                  <div className="d-flex flex-column align-content-between">
                    <div className='py-2'>
                      <b>
                        <span>
                          {!generaljackpot.type ?
                            (textosShow.competenciareferido || "Competencia referidos")
                            :
                            (textosShow['generaljackpot_' + generaljackpot.type] || generaljackpot.type)
                          }
                        </span>
                      </b>
                      { generaljackpot.custom_title &&
                        <>
                          <br/>
                          <b className='custom_title' style={{color: generaljackpot.custom_title_color}}>
                            {generaljackpot.custom_title}
                          </b>
                        </>
                        
                      }
                      {
                        generaljackpot.juego && (
                          juego ? (
                            <>
                              {/* Showed in screen that have only one game */}
                              <span className="d-flex justify-content-end" data-tip data-for={`generaljackpot_race_logo_${generaljackpot.id}`}>
                                <img className="sala-desktop-race-logo" src={raceRoomGif} />
                              </span>
                              <ReactTooltip id={generaljackpot.type ? `generaljackpot_race_logo_${generaljackpot.id}` : `referral-contenst-info-${generaljackpot.id}`}>
                                <p>
                                  {(textosShow.generaljackpot_megaevent || "Mega Event")}
                                </p>
                              </ReactTooltip>
                            </>
                          ) : (
                            <>
                              <span className="d-flex justify-content-between">
                                <b>
                                  {generaljackpot.juego.slug}
                                </b>
                                <Link to={`/game/${generaljackpot.juego.slug}`}>
                                  <img className="sala-mobile-game-logo" src={generaljackpot.juego.logo} />
                                </Link>
                              </span>
                              <span>
                                <Link to={`/game/${generaljackpot.juego.slug}`}>
                                  {textosShow.viewrooms || "View Rooms"}
                                </Link>
                              </span>
                            </>
                          )
                        )
                      }
                    </div>
                    <div className="py-2">
                      <br />
                      <span className='more_info'>
                        {textosShow.masinfo || "Más info"}
                      </span>
                    </div>
                    <div className="py-2">
                      <small>
                        {!generaljackpot.type ?
                          (textosShow.descripciondecompetenciadereferidos || "Descripción de competencia de referidos")
                          :
                          (textosShow['descripciongeneraljackpot_' + generaljackpot.type] || generaljackpot.type)
                        }
                        <span className="iconInfo pr-1">
                          <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                        </span>
                      </small>
                    </div>
                    {
                      <div className="py-2">
                        <CardText>
                          <small>
                            {
                              generaljackpot.tiempo.highlight ? (
                                <span className="iconTimeRemaining pr-1">
                                  <FontAwesomeIcon
                                    icon={faHourglassEnd}
                                    size="lg" />
                                </span>
                              ) : (
                                <span className="iconTimeRemaining pr-1">
                                  <FontAwesomeIcon
                                    icon={faHourglassHalf}
                                    size="lg" />
                                </span>
                              )
                            }
                            <span className="remaining_time">
                              {generaljackpot.tiempo.dias}d {generaljackpot.tiempo.horas}:{generaljackpot.tiempo.minutos}:{generaljackpot.tiempo.segundos}
                            </span>
                          </small>
                        </CardText>
                      </div>
                    }
                  </div>
                </CardBody>
                <CardFooter>
                <Collapse isOpen={showDepositNow === generaljackpot.id}>
                    <b>
                      {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                    </b>
                    <button className="button deposit_button enter_button mt-2">
                      <Link to="/deposit">
                        {textosShow.depositarahora || "Depositar ahora"}
                      </Link>
                    </button>
                  </Collapse>
                  <Collapse isOpen={showDepositNow !== generaljackpot.id}>
                    <button
                      className="button deposit_button enter_button"
                      onClick={onPlayGeneralJackpotClick.bind(gameComponent, generaljackpot)}>
                      {
                        !generaljackpot.registered ? (
                          textosShow.inscribirse || "Inscribirse"
                        ) : (
                          textosShow.infodeljuego || "Info del Juego"
                        )
                      }
                    </button>
                  </Collapse>
                </CardFooter>
              </Card>
            </Col>
          ))
        }
        {
          juego ? juego.salas.map((sala) => isValid(sala) && sala.onlyOnMobile === 0 && (
            <Col xs="12" sm="6" className={"rounded-lg py-3 tour_info " + getTipoTorneo(textosShow, juego, sala).classes.join(" ")}
              key={sala.id}>
              <Card className="rounded-lg shadow border-secondary h-100">
                <CardHeader className='p-0'>
                  <Row className="w-100 m-0">
                    <Col xs="5" className='entry_pay my-auto align-content-center'>
                      <div className="title">
                        {textosShow.costedeentradamin || "Coste de entrada"}
                      </div>
                      
                      <div className='d_flex'>
                        <span className={(juego.freeJuegoSalaId === sala.id /*|| sala.showReentry*/) ? 'strike' : ''}>
                          {defaultData.moneda.signo}{getEntryCosto(sala)}
                        </span>
                        { (juego.freeJuegoSalaId === sala.id) &&
                          <img className='free_icon'
                            src={freeTicketIcon}
                            width='70px'/>
                        }
                        {/* (sala.showReentry) &&
                          <span>
                            {defaultData.moneda.signo} {sala.costoReentry}
                          </span>
                      */}
                      </div>
                    </Col>
                    <Col xs="7" className='prize_card_header p-3'>
                      <div className="title">
                        {textosShow.premioalganador || "Premio al ganador"}
                      </div>
                      { (sala.modo == 'Ladder') ?
                        <>
                          <span>
                              <p><img src={gemIcon} className='iconBlink'/></p>{sala.totalLDRs + sala.totalLFRs}
                          </span>
                        </>
                        : ((sala.modo == 'Bonanza') ? 
                        <>
                          <span>
                            <p className='iconBlink'>{defaultData.moneda.signo}</p>{Number(sala.totalBDRs + sala.totalBFRs).toFixed(2)}
                          </span>
                        </> :
                        <>
                          <span className={`sign_length_${defaultData.moneda.signo.length}`}>
                            <p>{defaultData.moneda.signo}</p>{sala.premioTotal}
                          </span>
                          <div className='tour_add ic_close'>
                            +{sala.gemas}
                          </div>
                        </>)
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="d-flex flex-column align-content-between">
                    <div className='py-2'>
                      <b>
                        {getTipoTorneo(textosShow, juego, sala).data.map((data, index) => (
                          <span key={data.id} className={getCustomTitleClass(sala, index)} style={{color: getCustomTitleStyle(sala, index)}}>
                            {index > 0 ? " / " : ""}
                            {data.title}
                          </span>
                        ))}
                      </b>
                    </div>
                    <div className="py-2">
                      {(sala.cantJugador === -1) ? '∞' : sala.cantJugador}-{textosShow.jugadores || "Jugadores"}
                      <br />
                      <span className='more_info'>
                        {textosShow.masinfo || "Más info"}
                      </span>
                    </div>
                    {getTipoTorneo(textosShow, juego, sala).data.find(data => data.description) ? (
                      <div className="py-2">
                        {getTipoTorneo(textosShow, juego, sala).data.map((data, index) =>
                          data.description ?
                            (
                              <div>
                                <small>
                                  {data.description}
                                  {data.icon ?
                                    (
                                      <span className={`${data.iconContainerClass} pr-1`}>
                                        {data.icon}
                                      </span>
                                    ) :
                                    (null)
                                  }
                                </small>
                              </div>
                            ) :
                            (null)
                        )}
                      </div>
                    ) :
                      (null)
                    }
                    {
                      (sala.modo === "Jackpots") ? (
                        <div className="py-2">
                          <CardText>
                            <small>
                              {sala.tiempo.highlight ? (
                                <span className="iconTimeRemaining pr-1"><FontAwesomeIcon icon={faHourglassEnd} size="lg" /></span>
                              ) : (
                                <span className="iconTimeRemaining pr-1"><FontAwesomeIcon icon={faHourglassHalf} size="lg" /></span>
                              )}
                              <span className="remaining_time">
                                {sala.tiempo.dias}d {sala.tiempo.horas}:{sala.tiempo.minutos}:{sala.tiempo.segundos}
                              </span>
                            </small>
                          </CardText>
                        </div>
                      ) : (
                        null
                      )
                    }
                    { (sala.modo === "Ladder" || sala.modo === 'Bonanza') &&
                      <span>
                        {textosShow.tiemporestante || "Tiempo restante"}
                        <br />
                        {sala.tiempo.highlight ? (
                          <span className="iconTimeRemaining pr-1"><FontAwesomeIcon icon={faHourglassEnd} size="lg" /></span>
                        ) : (
                          <span className="iconTimeRemaining pr-1"><FontAwesomeIcon icon={faHourglassHalf} size="lg" /></span>
                        )}
                        <span className="remaining_time">
                          {getRewardRemainingTime(sala)}
                        </span>
                      </span>
                    }
                  </div>
                </CardBody>
                <CardFooter>
                  <Collapse isOpen={showDepositNow === sala.id}>
                    <b>
                      {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                    </b>
                    <button className="button deposit_button enter_button mt-2">
                      <Link to="/deposit">
                        {textosShow.depositarahora || "Depositar ahora"}
                      </Link>
                    </button>
                  </Collapse>
                  <Collapse isOpen={showDepositFree === sala.id}>
                    <b>
                      {textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis"}
                    </b>
                    <button className="button deposit_button enter_button mt-2">
                      <Link to="/deposit">
                        {textosShow.depositarahora || "Depositar ahora"} 
                      </Link>
                    </button>
                  </Collapse>
                  <Collapse isOpen={showDepositFree !== sala.id && showDepositNow !== sala.id}>
                    <button
                      className="button deposit_button enter_button"
                      onClick={onPlayClick.bind(gameComponent, sala)}>
                        {getButtonTitle(sala)}
                    </button>
                  </Collapse>
                </CardFooter>
              </Card>
            </Col>
          )) : (null)
        }
      </Row>
    </Container>
  )
}

export default SalasMobile;
