import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import CoinbaseButton from "../components/CoinbaseButton";
import Eagle from "../components/Eagle";
import PaymentWallButton from "../components/PaymentWallButton/";
import PaypalButton from "../components/PaypalButton";
import StripeCard from "../components/StripeCard";
import StripeForm from "../components/StripeForm";
import StripePaymentElement from "../components/StripePaymentElement";

class PaypalCheckout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
      paymentMethods: [],
      plan: null,
      payed: "paypal",
      successPay: 0,
      withPromotions: true,
    };

    this.urlGlobal = props.urlGlobal;
  }

  componentDidMount() {
    //this.getPlansData();
    //get query param code
    const queryparams = new URLSearchParams(window.location.search);
    if (queryparams.has("code")) {
      const code = queryparams.get("code");
      const params = JSON.parse(window.atob(code));
      this.setState({
        ...params.depositState,
      });
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const params = new URLSearchParams(window.location.search);
    if (
      this.state.plans.length > oldState.plans.length &&
      this.props.history.location.pathname === "/check_stripe" &&
      params.has("payment_intent")
    ) {
      this.setState({
        payed: "stripe",
      });
      this.deposit(params.get("payment_intent"));
    }
  }

  render() {
    const opener = window.opener;
    const referrer = document.referrer;

    const onSuccess = (paymentId) => {
      console.log("Successful payment!", paymentId);
      // post message to opener
      if (opener) {
        opener.postMessage(
          {
            data: paymentId,
            status: "success",
          },
          referrer
        );
      }
    };

    const onError = (err) => {
      console.log("Erroneous payment OR failed to load script!", err);
      if (opener) {
        opener.postMessage(
          {
            data: err,
            status: "error",
          },
          referrer
        );
      }
    };

    const onCancel = (data) => {
      console.log("Cancelled payment!", data);
      if (opener) {
        opener.postMessage(
          {
            data: data,
            status: "cancel",
          },
          referrer
        );
      }
    };

    return (
      <div className="content" id="profile">
        <div className="wrapper">
          <div className="page_content">
            <div
              className="c_center"
              style={{
                margin: "100px auto 0px auto",
              }}
            >
              <div className="c_top">
                <div className="c_bottom duelmaster">
                  <Eagle></Eagle>
                  <div className="leaves"></div>
                  <Row className="justify-content-center">
                    <h4>
                      {this.props.textosShow.paypalcheckout ||
                        "Paypal Checkout"}
                    </h4>
                  </Row>
                  {this.state.successPay === 0 ? (
                    <>
                      <Row className="justify-content-center text-dark py-3">
                        <Col xs="12" sm="5" className="text-center">
                          {this.state.plan && this.state.payed === "paypal" ? (
                            <PaypalButton
                              total={this.state.plan.valorReal}
                              onSuccess={onSuccess}
                              onError={onError}
                              onCancel={onCancel}
                            />
                          ) : null}
                          {this.state.plan && this.state.payed === "stripe" ? (
                            <StripeForm
                              urlGlobal={this.urlGlobal}
                              getHeadersToSend={this.props.getHeadersToSend.bind(
                                this
                              )}
                              total={this.state.plan.valorReal}
                              payed={this.state.payed}
                              type="deposit"
                              planId={this.state.plan.id}
                              onError={onError}
                            >
                              <StripeCard
                                urlGlobal={this.urlGlobal}
                                getHeadersToSend={this.props.getHeadersToSend.bind(
                                  this
                                )}
                                total={this.state.plan.valorReal}
                                textosShow={this.props.textosShow}
                                onSuccess={onSuccess}
                                onError={onError}
                              />
                            </StripeForm>
                          ) : null}
                          {this.state.plan &&
                          this.state.payed === "stripe_all" ? (
                            <StripeForm
                              urlGlobal={this.urlGlobal}
                              getHeadersToSend={this.props.getHeadersToSend.bind(
                                this
                              )}
                              total={this.state.plan.valorReal}
                              payed={this.state.payed}
                              type="deposit"
                              planId={this.state.plan.id}
                              onError={onError}
                            >
                              <StripePaymentElement
                                urlGlobal={this.urlGlobal}
                                getHeadersToSend={this.props.getHeadersToSend.bind(
                                  this
                                )}
                                total={this.state.plan.valorReal}
                                textosShow={this.props.textosShow}
                                onSuccess={onSuccess}
                                onError={onError}
                              />
                            </StripeForm>
                          ) : null}
                          {this.state.plan &&
                          this.state.payed === "coinbase" ? (
                            <CoinbaseButton
                              urlGlobal={this.urlGlobal}
                              getHeadersToSend={this.props.getHeadersToSend.bind(
                                this
                              )}
                              total={this.state.plan.valorReal}
                              onSuccess={onSuccess}
                              onError={onError}
                              onCancel={onCancel}
                            />
                          ) : null}
                          {this.state.plan && this.state.payed === "fortumo" ? (
                            <a
                              id="fmp-button"
                              href="#"
                              rel={`${this.getPayerObj().config.ref}`}
                            >
                              <img
                                src="https://assets.fortumo.com/fmp/fortumopay_150x50_red.png"
                                width="150"
                                height="50"
                                alt="Mobile Payments by Fortumo"
                                border="0"
                              />
                              {window.Fortumo && window.Fortumo.fmpWidget()}
                            </a>
                          ) : null}
                          {this.state.plan &&
                          this.state.payed === "payment_wall" ? (
                            <PaymentWallButton
                              urlGlobal={this.urlGlobal}
                              getHeadersToSend={this.props.getHeadersToSend.bind(
                                this
                              )}
                              total={this.state.plan.valorReal}
                              onSuccess={onSuccess}
                              onError={onError}
                              onCancel={onCancel}
                            />
                          ) : null}
                          {this.state.plan &&
                          this.state.payed === "neteller" ? (
                            <p>
                              {this.props.textosShow
                                .aunnoestalistaestaviadepago ||
                                "Aun no esta lista esta via de pago"}
                            </p>
                          ) : null}
                          {this.state.plan && this.state.payed === "webpay" ? (
                            <p>
                              {this.props.textosShow
                                .aunnoestalistaestaviadepago ||
                                "Aun no esta lista esta via de pago"}
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    </>
                  ) : this.state.successPay === 1 ? (
                    <div></div>
                  ) : (
                    <Row>
                      <Col>
                        {this.state.successPay === 2 ? (
                          <div>
                            <h1>
                              {this.props.textosShow
                                .felicitacionespagocompletado ||
                                "FELICITACIONES PAGO COMPLETADO!"}
                            </h1>
                            <p>
                              {this.props.textosShow
                                .seharecargadoexitosamente ||
                                "Se ha recargado exitosamente"}
                              :{this.props.defaultData.moneda.signo}{" "}
                              {this.state.plan.valorReal}{" "}
                              {this.props.textosShow.atucuenta ||
                                "a tu cuenta!"}
                            </p>
                            <p>
                              <Link to="/">
                                {this.props.textosShow.volveraportada ||
                                  "Volver a portada"}
                              </Link>
                            </p>
                          </div>
                        ) : (
                          <div>
                            <h1>
                              {this.props.textosShow.pagofallido ||
                                "PAGO FALLIDO!"}
                            </h1>
                            <p>
                              {this.props.textosShow
                                .nohemospodidocompletarelpago ||
                                "No hemos podido completar el pago. Revisa por favor que la cuenta que desees pagar tenga fondos!"}
                            </p>
                            <p>
                              <Link to="/">
                                {this.props.textosShow.volveraportada ||
                                  "Volver a portada"}
                              </Link>
                            </p>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row className="text-dark pt-3">
                    <Col>
                      <h5 className="text-center">
                        {this.props.textosShow.comoempezarunjuego}
                      </h5>
                      <div className="rules_text text-center p-0">
                        {this.props.textosShow.comoempezarunjuegoparrafo}
                      </div>
                    </Col>
                    <Col>
                      <h5 className="text-center">
                        {this.props.textosShow.otrainformacion}
                      </h5>
                      <div className="rules_text text-center p-0">
                        {this.state.withPromotions
                          ? this.props.textosShow
                              .otrainformacionparrafoconpromocion ||
                            "No, you need play games for unblock the money"
                          : this.props.textosShow.otrainformacionparrafo}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PaypalCheckout);
