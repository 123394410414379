import React, { Component } from 'react';

class FechaSel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      days: 31
    };
  }

  adjustDias() {
    if (this.props.dateSel.mes === 4 || this.props.dateSel.mes === 6 || this.props.dateSel.mes === 9 || this.props.dateSel.mes === 11) {
      this.props.dateSel.dia = 30;
    }
    else if (this.props.dateSel.mes === 2) {
      if (this.props.dateSel.ano % 4 === 0) {
        this.props.dateSel.dia = 29;
      }
      else {
        this.props.dateSel.dia = 28;
      }
    }
    else {
      this.props.dateSel.dia = 31;
    }
  }
  
  setDay(day) {
    this.props.dateSel.dia = day;
  }
  
  setMonth(month) {
    this.props.dateSel.mes = month;
    this.adjustDias();
  }
  
  setYear(year) {
    this.props.dateSel.ano = year;
    this.adjustDias();
  }

  render() {
    let ySince = (new Date()).getFullYear() - this.props.ySince;
    let yTo = (new Date()).getFullYear() - this.props.yTo;
    let years = [];
    while (yTo >= ySince) {
      years.push(yTo);
      yTo--;
    }
    
    let months = [
      {
        id: 1,
        nombre: (this.props.textosShow.enero || "Enero")
      },
      {
        id: 2,
        nombre: (this.props.textosShow.febrero || "Febrero")
      },
      {
        id: 3,
        nombre: (this.props.textosShow.marzo || "Marzo")
      },
      {
        id: 4,
        nombre: (this.props.textosShow.abril || "Abril")
      },
      {
        id: 5,
        nombre: (this.props.textosShow.mayo || "Mayo")
      },
      {
        id: 6,
        nombre: (this.props.textosShow.junio || "Junio")
      },
      {
        id: 7,
        nombre: (this.props.textosShow.julio || "Julio")
      },
      {
        id: 8,
        nombre: (this.props.textosShow.agosto || "Agosto")
      },
      {
        id: 9,
        nombre: (this.props.textosShow.septiembre || "Septiembre")
      },
      {
        id: 10,
        nombre: (this.props.textosShow.octubre || "Octubre")
      },
      {
        id: 11,
        nombre: (this.props.textosShow.noviembre || "Noviembre")
      },
      {
        id: 12,
        nombre: (this.props.textosShow.diciembre || "Diciembre")
      }
    ];

    let dayIni = 1;
    let dayFin = this.state.days;
    let days = [];
    while (dayIni <= dayFin) {
      days.push(dayIni);
      dayIni++;
    }

    return ( 
      <div>
        <div className='select'>
          <button className='main-item'>
            {this.props.dateSel ? this.props.dateSel.dia : 1}
          </button>
          <ul className="sub-menu lang_menu">
            <li>
              {
                days.map(day =>
                  <span className="link-button"
                        key={day}
                        onClick={() => this.setDay(day)}>
                    {day}
                  </span>
                )
              }
            </li>
          </ul>
        </div>
        <div className='select'>
          <button className='main-item'>
            {
              this.props.dateSel ? 
                months.find(m => m.id === parseInt(this.props.dateSel.mes)).nombre :
                "Enero"
            }
          </button>
          <ul className="sub-menu lang_menu">
            <li>
              {
                months.map(m =>
                  <span className="link-button"
                        key={m.id}
                        onClick={() => this.setMonth(m.id)}>
                    {m.nombre}
                  </span>
                )
              }
            </li>
          </ul>
        </div>
        <div className='select'>
          <button className='main-item'>
            {this.props.dateSel ? this.props.dateSel.ano : this.props.ySince}
          </button>
          <ul className="sub-menu lang_menu">
            <li>
              {
                years.map(year =>
                  <span className="link-button"
                        key={year}
                        onClick={() => this.setYear(year)}>
                    {year}
                  </span>
                )
              }
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default FechaSel;