import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";

class PaypalButton extends React.Component {
  render() {
    const onSuccess = (data, actions) => {
      const clase = this;
      // Capture the funds from the transaction
      return actions.order.capture().then(function (details) {
        // Show a success message to your buyer
        console.log("Transaction completed by " + details.payer.name.given_name);
        clase.props.onSuccess(data.orderID);
      });
    };

    const onCancel = (data) => {
      this.props.onCancel(data);
    };

    const onError = (err) => {
      console.error("Paypal error", err);
      this.props.onError(err);
    };

    let currency = "USD"; // or you can set this value from your props or state
    let total = this.props.total; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout

    let style = {
      label: "pay",
      size: "responsive", // small | medium | large | responsive
      shape: "rect", // pill | rect
      color: "black", // gold | blue | silver | black
      tagline: 0,
    };

    return (
      <>
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_ID }}>
          <PayPalButtons 
            style={style} 
            onError={onError}
            onApprove={onSuccess}
            onCancel={onCancel}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: total,
                    },
                  },
                ],
              });
            }}
            forceReRender={[total, currency]}
            />
        </PayPalScriptProvider>
      </>
    );
  }
}

export default PaypalButton;
