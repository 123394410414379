import React, { useState, useEffect, Children, isValidElement, cloneElement } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function StripeForm({ urlGlobal, getHeadersToSend, total, payed, type, planId, color, onError, children }) {
  const [clientSecret, setClientSecret] = useState('');
  const [isSetup, setIsSetup] = useState(false);
  const [trialDays, setTrialDays] = useState(0);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: 'flat',
      variables: {
        colorText: color ? color : '#30313d',
      },
      fontFamily: 'Open Sans, Arial, sans-serif',
      rules: {
        '.Tab:focus': {
          boxShadow: '#30313d',
        },
        '.Tab--selected:focus': {
          boxShadow: '#30313d',
        },
        '.Tab--selected': {
          
          backgroundColor: '#30313d'
        },
      }
    },
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const myHeaders = getHeadersToSend();
    setClientSecret('');
    fetch(urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "createStripeIntent",
        importe: total,
        payed: payed,
        type: type,
        planId: planId
      }),
      headers: myHeaders
    }).then((response) => {
      return response.json();
    }).then((result) => {
      if (result.success) {
        setClientSecret(result.client_secret);
        setIsSetup(result.is_setup);
        setTrialDays(result.trial_days);
      }
      else {
        onError(result.msg);
      }
    });
  }, [urlGlobal, JSON.stringify(getHeadersToSend()), total]);

  return <>
    {!clientSecret && 
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    }
    {
      clientSecret && <Elements stripe={stripePromise} options={options}>
        {Children.map(children, child => cloneElement(child, { clientSecret, isSetup, trialDays }))}
      </Elements>
    }
  </>;
};