import React, { Component } from 'react';

import $ from 'jquery';

class PartidaModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      gameSlug: "",
      sala: null
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      this.close();
    }
  };
  
  close() {
    $("#premios_modal").fadeOut("fast", function() {
      $("#premios_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };
  
  onPlayClick() {
    if (!this.state.sala) {
      return;
    }

    if (this.state.sala.modo == 'Spin') {
      this.showSpin();
    } else {
      this.playGame();
    }
  }

  playGame() {
    let clase = this;
    if (clase.props.loggedIn) {
      let myHeaders = clase.props.getHeadersToSend();
      let lan = clase.props.getLanActual();
      fetch(clase.props.urlGlobal + "api/juego", {
          method: "POST",
          body: JSON.stringify({
            action: "setPartida",
            juego: clase.state.gameSlug,
            sala: clase.state.sala.id,
            first: 1,
            lan: lan,
            reentry: clase.state.sala.showReentry ? 1 : 0
          }),
          headers: myHeaders
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          window.location = clase.props.urlGlobal + "playGame/" + clase.state.sala.slug + "/?gameMode=" + clase.state.sala.id + "&partida=" + result.partida.id + "&game=" + clase.state.gameSlug;
        }
        else {
          //alert(result.msg);
          clase.props.openAlertModal(
            clase.props.textosShow[result.msg] || result.msg,
            clase.props.textosShow[`${result.msg}descripcion`] || 'Error',
            result.type && result.type === 'purchase'
          )
        }
      });
    }
    else{
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  };

  showSpin() {
    this.close();
    this.props.showSpinModal(this.state.gameSlug, this.state.sala.id, this.state.sala.slug, 0, null);
  }
  
  render() {
    return (
      <div 
        className="modalx-overlay close_mw"
        id="premios_modal_overlay">
        <div 
          className='modal_window' 
          id="premios_modal">
          <button 
            id="close_modal" 
            className="link-button close_mw"
            onClick={this.close.bind()}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
              {
                    this.state.sala ? (
                      <div>
                        <h5>
                          {this.props.textosShow.totalpremios || "Total Premios"}
                        </h5>
                        <div className='premio'>
                          <div>
                            {this.props.defaultData.moneda.signo} {this.state.sala.premioTotal}
                          </div>
                        </div>
                        <table>
                          <thead>
                            <tr className='subtitle'>
                              <td colSpan="2">
                                <span>
                                  <center>
                                    {this.props.textosShow.totalpremios || "Premios"}
                                  </center>
                                </span>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.sala.premios.map((premio) =>
                                <tr key={premio.ind}>
                                  <td>
                                    <span>
                                      #{premio.ind}
                                    </span>
                                  </td>
                                  <td className='entry_pay'>
                                    <span>
                                      {this.props.defaultData.moneda.signo} {premio.valor}
                                      <small className="tour_add">
                                        + {premio.gemas}
                                      </small>
                                    </span>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                        <div className='vs_player'>
                          <h6>
                            {this.state.sala.modo}
                          </h6>
                        </div>
                        <div className='modal_button'>
                          <button 
                            className='button button_regular'
                            onClick={this.onPlayClick.bind(this)}>
                            {this.props.textosShow.ingresar || "Ingresar"}
                          </button>
                        </div>
                      </div>
                    ) : (
                      null
                    )
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default PartidaModal;
